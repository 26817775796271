import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const query = graphql`
  query getPosts {
    posts: allFile(
      filter: {
        sourceInstanceName: { eq: "generated" }
        extension: { eq: "mdx" }
        childMdx: {
          frontmatter: { type: { eq: "POST" } }
          fields: { show: { eq: true } }
        }
      }
      sort: { order: DESC, fields: childMdx___frontmatter___published_on }
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              title
              slug
              tags
              description
              published_on(formatString: "MMM DD, YYYY")
            }
            excerpt
          }
          name
        }
      }
    }
  }
`

const Posts = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Wrapper>
        <div className="container">
          <h1>Blog</h1>
          {data.posts.edges.map(({ node }, index) => {
            return (
              <div key={index}>
                <AniLink fade to={`/${node.childMdx.frontmatter.slug}`}>
                  <h2>{node.childMdx.frontmatter.title}</h2>
                </AniLink>
                <div className="published-on">
                  {node.childMdx.frontmatter.published_on}
                </div>
                <span
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: node.childMdx.frontmatter.description,
                  }}
                />
                <AniLink
                  className="more"
                  fade
                  to={`/${node.childMdx.frontmatter.slug}`}
                >
                  Read more
                </AniLink>
                <hr />
              </div>
            )
          })}
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h2 {
      margin-bottom: 0.25rem;
    }

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        margin-bottom: 0.4em;
      }
    }

    hr {
      border: 0.5px solid var(--primaryColor);
      width: 50%;
      margin: auto;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    a {
      text-decoration: none;
    }

    .desc {
      margin-bottom: 1rem;
    }

    .more {
      margin-left: 1em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .published-on {
    padding-bottom: 1rem;
    color: gray;
    font-weight: 1rem;
    font-weight: bold;
  }

  .breadcrumb {
    margin: 2em;
    font-family: var(--headerFont);
    list-style-type: none;
    transition: var(--mainTransition);
    letter-spacing: var(--mainSpacing);
    color: var(--mainBlack);

    svg {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 500px) {
    .container {
      padding: 0 1em;
      width: 100vw;
    }
  }
`

export default Posts
